import React from 'react';
import {
    Page,
    Card,
    CardContent,
    Icon,
    Block,
    List,
    Button,
    ListInput,
    Preloader,
} from 'framework7-react';

import {API_URL} from '../../../api';
import IwdBottomBar from '../../shared/bottomBar/bottomBar';
import PageBlock from '../../shared/pageBlock/pageBlock';

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSendBtn: true,
            email: '',
            errMgs: null,
            formError: false
        };
    }

    componentDidMount(){
        document.title = 'Påmeldinger.no - Resett passord';
    }

        //Update email
        updateEmail(e){
            this.setState({
              email: e.target.value.replace(/\s/g,'').toLowerCase(),
              showSendBtn: true,
              loading: false,
              showSuccessMgs: false,
            });
        }
  
        checkForm(){
            const app = this.$f7;
            
            if(!app.input.validateInputs('.req_register')){
              return false;
            }

            if(this.state.password !== this.state.password2){
              this.setState({
                  error_password_not_equal:true
              });
              return false;
          }

          this.postXhr();

        }
    
        postXhr() {
            const self = this;

            self.setState({
                showSendBtn: false,
                loading: true,
            });
    
            fetch(API_URL+'/user/password/reset', {
              method: 'POST',
              body: JSON.stringify({
                email: this.state.email
              }),
              headers : new Headers(),
            })
            .then(res => res.json())
            .then(
              (result) => {
                if(result.err_code === 0){
                  
                    self.setState({
                        success: true,
                        loading: false,
                        showSuccessMgs: false,
                        showSendBtn: true,
                        errMgs: false
                    });
    
                }else{
                    self.setState({
                    errMgs: result.err_mgs,
                    showSendBtn: true,
                    loading: false,
                    showSuccessMgs: false,
                  });
                }
              },
              (error) => {
                this.setState({
                  errMgs: 'En ukjent feil har oppstått, prøv igjen senere.',
                  showSendBtn: true,
                  loading: false,
                  showSuccessMgs: false,
                });
              }
            )
          }

  render() {
    return (
    <Page>
             {this.state.loading_invite
        ? (
            <div className="text-align-center" style={{margin:'26px'}}>
              <Preloader></Preloader>
            </div>
        ) : (
            <PageBlock width="380px">
            <Block>
                <h3>Resett passord</h3>
                <p className="text-align-center" style={{fontSize:'12px',color:'#666',paddingLeft:'20px',paddingRight:'20px'}}>
                Skriv inn din e-postadresse for å resette ditt passord. Dersom din e-postadresse er registrert på en bruker, vil du motta en e-post der du kan resette ditt passord.
                </p>
            </Block>
            {this.state.errMgs &&
                <Card style={{color:'#fff', backgroundColor:'#e74c3c', marginTop:'36px'}}>
                  <CardContent>
                    <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                    <span style={{fontSize:'18px'}}>{this.state.errMgs}</span>
                  </CardContent>
                </Card>
            }
            {this.state.success &&
                <Card style={{color:'#fff', backgroundColor:'#2ecc71', marginTop:'36px'}}>
                  <CardContent>
                  En e-post med instruksjoner om hvordan du resetter ditt passord har blitt sendt til din e-postadresse.
                  </CardContent>
                </Card>
            }
            <Card style={{marginBottom:'32px'}}>
                <CardContent style={{paddingTop:'28px'}}>
                <List className="req_register">
                    <ListInput
                        outline
                        label="Epost"
                        type="email"
                        placeholder="Skriv inn din epostadresse"
                        value={this.state.email}
                        onChange={(e) => this.updateEmail(e)}
                        autocomplete="email"
                        required
                        pattern="[^@]+@[^@]+\.[a-zA-Z]{2,}"
                        errorMessage="Skriv inn en gyldig e-postadresse"
                    />
                </List>
                {this.state.loading &&
                <Block className="text-align-center" style={{marginTop:'40px', marginBottom:'20px'}}>
                    <Preloader></Preloader>
                </Block>
                }
                {this.state.showSendBtn &&
                <Block style={{marginTop:'34px', marginBottom:'10px'}}>
                    <Button onClick={() => this.checkForm()} large raised fill>Send e-post</Button>
                </Block>
                }
                </CardContent>
            </Card>
      </PageBlock>
      )}
      <IwdBottomBar/>
    </Page>
    );
  }
};
