import React from 'react';
import {
    Page,
    Preloader,
} from 'framework7-react';
import {API_URL} from '../../../api';


export default class extends React.Component {

    componentDidMount(){
        const app = this.$f7;
        document.title = 'Påmeldinger.no - Vent litt';
        let url = new URL(window.location.href.replace('#',''));

        const urlParams = new URLSearchParams(url.search);

        const access_token = urlParams.get('access_token');
        const invite = urlParams.get('state');

        if(access_token){
            this.signinOAuthToken(access_token,'fb',invite);
        }else{
            app.views.main.router.navigate(`/`);
        }

    }
        
        signinOAuthToken = (t,issuer,invite) => {
            const app = this.$f7;
            const self = this;
            fetch(API_URL+'/user/oauth', {
              method: 'POST',
              body: JSON.stringify({
                token: t,
                issuer: issuer,
                invite: invite
              }),
              headers : new Headers(),
            })
            .then(res => res.json())
            .then(
              (result) => {
                if(result.err_code === 0){
                  
                  self.sucess(result.sID);
        
                }else{
                  app.toast.create({
                    text: result.err_mgs,
                    position: 'bottom',
                    closeTimeout: 3500,
                  }).open();
                  app.views.main.router.navigate(`/`);
                }
              },
              (error) => {
                app.toast.create({
                    text: 'En ukjent feil har oppstått, prøv igjen senere.',
                    position: 'bottom',
                    closeTimeout: 3500,
                }).open();
                app.views.main.router.navigate(`/`);
              }
            )
        
        }

        sucess(sID){
            let d = new Date();
            d.setTime(d.getTime() + (60*24*60*60*1000));
            const expires = "expires="+ d.toUTCString();
            document.cookie = "sID=" + sID + ";" + expires + ";path=/"
            window.location.replace('/');
        }

  render() {
    return (
    <Page>
      <div className="text-align-center" style={{marginTop:'160px',marginBottom:'160px'}}>
        <Preloader></Preloader>
        <p>Vent litt</p>
      </div>
    </Page>
    );
  }
};
