import React from 'react';
import {
  App,
  View,
} from 'framework7-react';

import routes from '../routes';

  const f7params = {
    id: 'no.iwd.ticket.admin',
    name: 'Ticket',
    theme: 'md',
    stackPages: true,
    routes,
    view: {
      animate: false,
      pushState: true,
      pushStateSeparator:"",
    },
  };

  export default class extends React.Component {

    render() {
      return (
          <App params={f7params}>
            <View id="main-view" url="/" main className="safe-areas"/>
          </App>
      )
    }
  };
