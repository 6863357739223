import RegisterPage from './components/pages/RegisterPage/main';
import SignInPage from './components/pages/SignInPage/main';
import InvitePage from './components/pages/InvitePage/main';
import OauthFb from './components/pages/oauth/fb';
import OauthLinkedin from './components/pages/oauth/linkedin';
import ResetPasswordPage from './components/pages/ResetPasswordPage/main';
import ResetPasswordCheckPage from './components/pages/ResetPasswordPage/reset_check';

export default [
  {
    path: '/register',
    component: RegisterPage,
  },
  {
    path: '/password/reset',
    component: ResetPasswordPage,
  },
  {
    path: '/password/reset/:sURL',
    component: ResetPasswordCheckPage,
  },
  {
    path: '/invite/:sURL',
    component: InvitePage
  },
  {
    path: '/oauth/fb',
    component: OauthFb
  },
  {
    path: '/oauth/linkedin',
    component: OauthLinkedin
  },
  {
    path: '(.*)',
    component: SignInPage,
  },
];
