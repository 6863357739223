import React from "react";
import { Page, Card, CardContent, Icon, Block, List, Button, ListInput, Preloader, BlockTitle, Popup, NavRight, Navbar } from "framework7-react";
import GoogleLogin from "react-google-login";
import { LinkedIn } from "react-linkedin-login-oauth2";

import { API_URL } from "../../../api";

import IwdBottomBar from "../../shared/bottomBar/bottomBar";
import Seperator from "../../shared/separator/separator";

import PageBlock from "../../shared/pageBlock/pageBlock";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSendBtn: true,
      loading: false,
      email: "",
      password: "",
      //error
      errMgs: null,
      formError: false,
    };
  }

  componentDidMount() {
    document.title = "Påmeldinger.no - Logg inn";
  }

  //Update email
  updateEmail(e) {
    this.setState({
      errClassEmail: "",
      email: e.target.value.replace(/\s/g, "").toLowerCase(),
      showSendBtn: true,
      loading: false,
      showSuccessMgs: false,
    });
  }
  //Update password
  updatePassword(e) {
    this.setState({
      errClassEmail: "",
      password: e.target.value,
      showSendBtn: true,
      loading: false,
      showSuccessMgs: false,
    });
  }

  checkForm() {
    const self = this;

    if (!this.state.email) {
      self.setState({
        errMgs_signin: "Skriv inn din epostadresse.",
        formError: true,
        loading_signin: false,
      });
      return false;
    }

    if (!this.state.password) {
      self.setState({
        errMgs_signin: "Skriv inn ditt passord.",
        formError: true,
        loading_signin: false,
      });
      return false;
    }

    self.setState({
      errMgs_signin: null,
      loading_signin: true,
      showSuccessMgs: false,
    });

    self.postXhr();
  }

  postXhr() {
    const self = this;
    fetch(API_URL + "/user/signin", {
      method: "POST",
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
      headers: new Headers(),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.err_code === 0) {
            self.sucess(result.sID);
          } else {
            self.setState({
              errMgs_signin: result.err_mgs,
              loading_signin: false,
            });
          }
        },
        (error) => {
          self.setState({
            errMgs_signin: "En ukjent feil har oppstått, prøv igjen senere.",
            loading_signin: false,
          });
        }
      );
  }

  sucess(sID) {
    let d = new Date();
    d.setTime(d.getTime() + 60 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = "sID=" + sID + ";" + expires + ";path=/";
    window.location.replace("/");
  }

  signinWithGoogle = () => {
    return (
      <GoogleLogin
        clientId="854823825635-0239qhnsdvouhh6ou0f48dmgdibeefsc.apps.googleusercontent.com"
        render={(r) => (
          <Button onClick={r.onClick} disabled={r.disabled} raised large fill color="white" textColor="defalt">
            <div className="signin-google-g-logo" style={{ float: "left", height: "50px", width: "50px", marginLeft: "auto", marginRight: "auto" }} />
            <span>Logg inn med Google</span>
          </Button>
        )}
        onRequest={() => this.setState({ loading_google: true })}
        buttonText="Login"
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogleError}
        cookiePolicy={"single_host_origin"}
      />
    );
  };

  responseGoogle = (r) => {
    this.setState({ loading_google: false });

    if (r.tokenId) {
      this.signinOAuthToken(r.accessToken, "gl");
    }
  };

  responseGoogleError = (response) => {
    this.setState({ loading_google: false });
  };

  signinWithFB = () => {
    return (
      <Button popupOpen=".facebook-login-info-popup" raised large fill color="white" textColor="defalt">
        <div className="signin-facebook-f-logo" style={{ float: "left", height: "50px", width: "50px", marginLeft: "auto", marginRight: "auto" }} />
        <span>Logg inn med Facebook</span>
      </Button>
    );
    /*
    return <FacebookLogin
      appId="544039702910786"
      callback={this.responseFacebook}
      fields="name,email"
      redirectUri="https://admin.pameldinger.no/oauth/fb"
      responseType="token"
      render={r => (
        r.isProcessing ?
        <Block className="text-align-center" style={{marginTop:'28px', marginBottom:'28px'}}>
          <Preloader></Preloader>
        </Block>
        :
        <Button onClick={r.onClick} disabled={!r.isSdkLoaded} raised large fill color="white" textColor="defalt">
          <div className="signin-facebook-f-logo" style={{float:'left',height:'50px',width:'50px',marginLeft:'auto',marginRight:'auto'}}/>
          <span>Logg inn med Facebook</span>
        </Button>
      )}
    />
    */
  };

  responseFacebook = (r) => {
    if (r.id) {
      this.signinOAuthToken(r.accessToken, "fb");
    }
  };

  signinOAuthToken = (t, issuer) => {
    this.setState({ loading: true });
    const self = this;
    fetch(API_URL + "/user/oauth", {
      method: "POST",
      body: JSON.stringify({
        token: t,
        issuer: issuer,
      }),
      headers: new Headers(),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.err_code === 0) {
            self.sucess(result.sID);
          } else {
            self.setState({
              errMgs: result.err_mgs,
              loading: false,
            });
          }
        },
        (error) => {
          self.setState({
            errMgs: "En ukjent feil har oppstått, prøv igjen senere.",
            loading: false,
          });
        }
      );
  };

  signinWithLinkedIn = () => {
    return (
      <LinkedIn
        clientId="78pil7e9kakh5d"
        scope="r_emailaddress r_liteprofile"
        onFailure={(response) => this.responseLinkedInError(response)}
        onSuccess={(response) => this.responseLinkedInSuccess(response)}
        redirectUri="https://admin.pameldinger.no/oauth/linkedin"
        renderElement={({ onClick, disabled }) => (
          <Button onClick={onClick} disabled={disabled} raised large fill color="white" textColor="defalt">
            <div className="signin-linkedin-in-logo" style={{ float: "left", height: "50px", width: "50px", marginLeft: "auto", marginRight: "auto" }} />
            <span>Logg inn med LinkedIn</span>
          </Button>
        )}
      />
    );
  };

  responseLinkedInError = (response) => {
    console.log(response);
  };

  responseLinkedInSuccess = (r) => {
    if (r.code) {
      this.signinOAuthToken(r.code, "ln");
    }
  };

  render() {
    return (
      <Page>
        {this.state.loading ? (
          <Block className="text-align-center" style={{ marginTop: "66px", marginBottom: "100px" }}>
            <Preloader></Preloader>
            <p style={{ marginTop: "20px" }}>Vent litt</p>
          </Block>
        ) : (
          <>
            <PageBlock width="360px">
              <h3>Velkommen tilbake!</h3>
              {this.state.errMgs && (
                <Card style={{ color: "#fff", backgroundColor: "#e74c3c" }}>
                  <CardContent>
                    <Icon material="error" style={{ marginRight: "12px", marginTop: "-5px" }} />
                    <span style={{ fontSize: "18px" }}>{this.state.errMgs}</span>
                  </CardContent>
                </Card>
              )}
              <Block style={{ marginBottom: "16px" }}>
                {this.state.loading_google ? (
                  <Block className="text-align-center" style={{ marginTop: "32px", marginBottom: "32px" }}>
                    <Preloader></Preloader>
                  </Block>
                ) : (
                  this.signinWithGoogle()
                )}
              </Block>
              {/*
                <Block style={{marginBottom:'16px',marginTop:'4px'}}>
                {this.signinWithFB()}
                </Block>
                <Block style={{marginBottom:'16px',marginTop:'4px'}}>
                  {this.signinWithLinkedIn()}
                </Block>
              */}
              <Seperator text="eller" />
              <Card>
                {this.state.errMgs_signin && (
                  <CardContent style={{ color: "#fff", backgroundColor: "#e74c3c" }}>
                    <Icon material="error" style={{ marginRight: "12px", marginTop: "-5px" }} />
                    <span style={{ fontSize: "18px" }}>{this.state.errMgs_signin}</span>
                  </CardContent>
                )}
                <CardContent>
                  <BlockTitle className="text-align-center" style={{ marginTop: "8px", marginBottom: "26px" }}>
                    E-post og passord
                  </BlockTitle>
                  <List>
                    <ListInput outline label="Epost" type="email" placeholder="Skriv inn din epostadresse" value={this.state.email} onChange={(e) => this.updateEmail(e)} autocomplete="email" />
                    <ListInput outline label="Passord" type="password" placeholder="Skriv inn ditt passord" value={this.state.password} onChange={(e) => this.updatePassword(e)} />
                  </List>
                  {this.state.loading_signin ? (
                    <Block className="text-align-center" style={{ marginTop: "36px", marginBottom: "18px" }}>
                      <Preloader></Preloader>
                    </Block>
                  ) : (
                    <Block style={{ marginTop: "30px", marginBottom: "8px" }}>
                      <Button onClick={() => this.checkForm()} large raised fill>
                        Logg inn
                      </Button>
                    </Block>
                  )}
                </CardContent>
              </Card>
              <Block className="text-align-center" style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <Button outline href="/password/reset">
                  Glemt passord?
                </Button>
                <br />
                <Button outline href="/register">
                  Ikke bruker? Registrer deg
                </Button>
              </Block>
            </PageBlock>
            <IwdBottomBar />
          </>
        )}
      </Page>
    );
  }
}
