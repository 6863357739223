import * as React from 'react';
import {
    Block,
    Col,
    Row,
    Link,
    BlockTitle
} from 'framework7-react';

import PageBlock from '../pageBlock/pageBlock';

export default class IwdBottomBar extends React.Component {
    render() {

      return <PageBlock width="600px" color="#dfe4ea">
        <Row noGap style={{marginTop:'8px'}}>
          <Col width="100" small="60">
            <BlockTitle medium>Kontakt</BlockTitle>
            <Block>
              <p>
                Har du et spørsmål? Ta kontakt på e-post til: <Link external href="mailto:post@iwd.no"><b>post@iwd.no</b></Link>
              </p>
              <p>
                Spørsmål om faktura? Ta kontakt på e-post til: <Link external href="mailto:faktura@iwd.no"><b>faktura@iwd.no</b></Link>
              </p>
            </Block>
          </Col>
          <Col width="100" small="40">
            <BlockTitle medium>Annet</BlockTitle>
            <Block>
              <p>
              <Link external target="_blank" href="https://pameldinger.no/vilkar">
                Vilkår for deltakere
              </Link>
              </p>
              <p style={{marginTop:'-8px'}}>
              <Link external target="_blank" href="https://pameldinger.no/vilkar-for-arrangor">
                Vilkår for arrangører
              </Link>
              </p>
              <p style={{marginTop:'-8px'}}>
              <Link external target="_blank" href="https://pameldinger.no/vilkar-for-brukere">
                Vilkår for brukere
              </Link>
              </p>
              <p style={{marginTop:'-8px'}}>
              <Link external target="_blank" href="https://pameldinger.no/cookies">
              Informasjonskapsler (Cookies)
              </Link>
              </p>
              <p style={{marginTop:'-8px'}}>
              <Link external target="_blank" href="https://pameldinger.no/gdpr">
              Databehandleravtale og GDPR 
              </Link>
              </p>
              <p style={{marginTop:'-8px'}}>
              <Link external target="_blank" href="https://pameldinger.no/personvern">
              Personvernerklæring
              </Link>
              </p>
            </Block>
          </Col>

        </Row>
        <Block className="text-align-center" style={{width:'100%',marginLeft:'auto',marginRight:'auto'}}>
            <Link external href="https://pameldinger.no/">PÅMELDINGER.NO AS | Org.Nr. 821 190 932 MVA | www.pameldinger.no</Link>
        </Block>
      </PageBlock>
    }
};