import React from "react";
import { Page, Card, CardContent, Icon, Block, BlockTitle, List, Row, Col, Button, ListInput, Preloader, Link } from "framework7-react";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { LinkedIn } from "react-linkedin-login-oauth2";

import { API_URL } from "../../../api";
import IwdBottomBar from "../../shared/bottomBar/bottomBar";
import PageBlock from "../../shared/pageBlock/pageBlock";
import Separator from "../../shared/separator/separator";

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSendBtn: true,
      loading: false,
      name: "",
      email: "",
      password: "",
      password2: "",
      //error
      errMgs: null,
      formError: false,
      showTermsPopup: false,
    };
  }

  componentDidMount() {
    document.title = "Påmeldinger.no - Registrer deg";
  }
  //Update name
  updateName(e) {
    let name = e.target.value.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
    this.setState({
      name: name,
      showSendBtn: true,
      loading: false,
      showSuccessMgs: false,
    });
  }
  //Update email
  updateEmail(e) {
    this.setState({
      email: e.target.value.replace(/\s/g, "").toLowerCase(),
      showSendBtn: true,
      loading: false,
      showSuccessMgs: false,
    });
  }
  //Update password
  updatePassword(e) {
    this.setState({
      password: e.target.value,
      showSendBtn: true,
      loading: false,
      showSuccessMgs: false,
    });
  }
  //Update password
  updateConfirmPassword(e) {
    this.setState({
      error_password_not_equal: false,
      password2: e.target.value,
      showSendBtn: true,
      loading: false,
      showSuccessMgs: false,
    });
  }

  checkForm() {
    const app = this.$f7;

    if (!app.input.validateInputs(".req_register")) {
      return false;
    }

    if (this.state.password !== this.state.password2) {
      this.setState({
        error_password_not_equal: true,
      });
      return false;
    }

    this.postXhr();
  }

  postXhr() {
    const self = this;

    self.setState({
      errMgs: null,
      showSendBtn: false,
      loading: true,
      showSuccessMgs: false,
    });

    fetch(API_URL + "/user/register", {
      method: "POST",
      body: JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
      }),
      headers: new Headers(),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.err_code === 0) {
            this.sucess(result.sID);
          } else {
            self.setState({
              errMgs: result.err_mgs,
              showSendBtn: true,
              loading: false,
              showSuccessMgs: false,
            });
          }
        },
        (error) => {
          this.setState({
            errMgs: "En ukjent feil har oppstått, prøv igjen senere.",
            showSendBtn: true,
            loading: false,
            showSuccessMgs: false,
          });
        }
      );
  }

  sucess(sID) {
    let d = new Date();
    d.setTime(d.getTime() + 60 * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = "sID=" + sID + ";" + expires + ";path=/";
    window.location.replace("/");
  }

  signinWithGoogle = () => {
    return (
      <GoogleLogin
        clientId="854823825635-0239qhnsdvouhh6ou0f48dmgdibeefsc.apps.googleusercontent.com"
        render={(r) => (
          <Button onClick={r.onClick} disabled={r.disabled} raised large fill color="white" textColor="defalt">
            <div className="signin-google-g-logo" style={{ float: "left", height: "50px", width: "50px", marginLeft: "auto", marginRight: "auto" }} />
            <span>Fortsett med Google</span>
          </Button>
        )}
        onRequest={() => this.setState({ loading_google: true })}
        buttonText="Login"
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogleError}
        cookiePolicy={"single_host_origin"}
      />
    );
  };

  responseGoogle = (r) => {
    this.setState({ loading_google: false });
    if (r.tokenId) {
      this.signinOAuthToken(r.accessToken, "gl");
    }
  };
  responseGoogleError = (response) => {
    this.setState({ loading_google: false });
  };

  signinWithFB = () => {
    return (
      <FacebookLogin
        appId="544039702910786"
        callback={this.responseFacebook}
        fields="name,email"
        redirectUri="https://admin.pameldinger.no/oauth/fb"
        responseType="token"
        render={(r) =>
          r.isProcessing ? (
            <Block className="text-align-center" style={{ marginTop: "28px", marginBottom: "28px" }}>
              <Preloader></Preloader>
            </Block>
          ) : (
            <Button onClick={r.onClick} disabled={!r.isSdkLoaded} raised large fill color="white" textColor="defalt">
              <div className="signin-facebook-f-logo" style={{ float: "left", height: "50px", width: "50px", marginLeft: "auto", marginRight: "auto" }} />
              <span>Fortsett med Facebook</span>
            </Button>
          )
        }
      />
    );
  };
  responseFacebook = (r) => {
    if (r.id) {
      this.signinOAuthToken(r.accessToken, "fb");
    }
  };

  signinWithLinkedIn = () => {
    return (
      <LinkedIn
        clientId="78pil7e9kakh5d"
        scope="r_emailaddress r_liteprofile"
        onFailure={(response) => this.responseLinkedInError(response)}
        onSuccess={(response) => this.responseLinkedInSuccess(response)}
        redirectUri="https://admin.pameldinger.no/oauth/linkedin"
        renderElement={({ onClick, disabled }) => (
          <Button onClick={onClick} disabled={disabled} raised large fill color="white" textColor="defalt">
            <div className="signin-linkedin-in-logo" style={{ float: "left", height: "50px", width: "50px", marginLeft: "auto", marginRight: "auto" }} />
            <span>Fortsett med LinkedIn</span>
          </Button>
        )}
      />
    );
  };

  responseLinkedInError = (response) => {
    console.log(response);
  };

  responseLinkedInSuccess = (r) => {
    if (r.code) {
      this.signinOAuthToken(r.code, "ln");
    }
  };

  signinOAuthToken = (t, issuer) => {
    this.setState({ loading: true });
    const self = this;
    fetch(API_URL + "/user/oauth", {
      method: "POST",
      body: JSON.stringify({
        token: t,
        issuer: issuer,
      }),
      headers: new Headers(),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.err_code === 0) {
            self.sucess(result.sID);
          } else {
            self.setState({
              errMgs: result.err_mgs,
              loading: false,
            });
          }
        },
        (error) => {
          self.setState({
            errMgs: "En ukjent feil har oppstått, prøv igjen senere.",
            loading: false,
          });
        }
      );
  };

  render() {
    return (
      <Page>
        <PageBlock>
          <Row>
            <Col width="100" medium="50">
              <BlockTitle medium>Kom i gang</BlockTitle>
              <Block>
                <p>Takk for at du vil opprette en bruker! Din bruker er personlig, og du bruker den til å administrere arrangementer, påmeldinger og betalinger for organisasjoner du tilhører.</p>
              </Block>
              <BlockTitle medium>Din organisasjon</BlockTitle>
              <Block>
                <p>Dersom din organisasjon ikke er opprettet enda, må du gjøre dette etter at du har registrert deg.</p>
                <p>Dersom du skal bli medlem i en eksisterende organisasjon, må en administrator legge til din bruker.</p>
                <p>
                  <Link external href="https://pameldinger.no/kom-i-gang">
                    Her finner du en innføring i hvordan du kommer i gang.
                  </Link>
                </p>
              </Block>
            </Col>
            <Col width="100" medium="50">
              <Block style={{ marginBottom: "-16px" }}>
                <h3 style={{ color: "#000" }}>Opprett din bruker nå</h3>
                <p className="text-align-center" style={{ marginTop: "-12px", marginBottom: "10px", fontSize: "12px", color: "#666", paddingLeft: "20px", paddingRight: "20px" }}>
                  Brukeren din er personlig. Du skal <b>ikke</b> opprette en bruker for din bedrift.
                </p>
              </Block>

              {this.state.errMgs && (
                <Card style={{ color: "#fff", backgroundColor: "#e74c3c", marginTop: "36px" }}>
                  <CardContent>
                    <Icon material="error" style={{ marginRight: "12px", marginTop: "-5px" }} />
                    <span style={{ fontSize: "18px" }}>{this.state.errMgs}</span>
                  </CardContent>
                </Card>
              )}
              <Block style={{ marginBottom: "14px" }}>
                {this.state.loading_google ? (
                  <Block className="text-align-center" style={{ marginTop: "34px", marginBottom: "34px" }}>
                    <Preloader></Preloader>
                  </Block>
                ) : (
                  this.signinWithGoogle()
                )}
              </Block>
              {/*
                <Block style={{marginBottom:'6px',marginTop:'14px'}}>
                {this.signinWithFB()}
                </Block>
                <Block style={{marginBottom:'6px',marginTop:'14px'}}>
                  {this.signinWithLinkedIn()}
                </Block>

                */}
              <Separator text="eller" />
              <Card>
                <CardContent>
                  <BlockTitle className="text-align-center" style={{ marginTop: "8px", marginBottom: "22px" }}>
                    E-post og passord
                  </BlockTitle>
                  <List className="req_register">
                    <ListInput outline label="Epost" type="email" placeholder="Skriv inn din epostadresse" value={this.state.email} onChange={(e) => this.updateEmail(e)} autocomplete="email" required pattern="[^@]+@[^@]+\.[a-zA-Z]{2,}" errorMessage="Skriv inn en gyldig e-postadresse" />
                    <ListInput outline label="Fullt navn" type="text" placeholder="Skriv inn ditt navn" value={this.state.name} onChange={(e) => this.updateName(e)} autocomplete="name" required errorMessage="Skriv inn ditt navn" />
                    <ListInput outline label="Passord" type="password" placeholder="Opprett passord" value={this.state.password} onChange={(e) => this.updatePassword(e)} required pattern="^.{6,}$" errorMessage="Passordet må være minimum 6 bokstaver" />
                    <ListInput outline label="Gjenta passord" type="password" placeholder="Gjenta passord" value={this.state.password2} onChange={(e) => this.updateConfirmPassword(e)} errorMessage="Passordet er ikke likt" errorMessageForce={this.state.error_password_not_equal} />
                  </List>
                  {this.state.loading && (
                    <Block className="text-align-center" style={{ marginTop: "40px", marginBottom: "10px" }}>
                      <Preloader></Preloader>
                    </Block>
                  )}
                  {this.state.showSendBtn && (
                    <Block style={{ marginTop: "34px", marginBottom: "0" }}>
                      <Button onClick={() => this.checkForm()} large raised fill>
                        Opprett bruker
                      </Button>
                    </Block>
                  )}
                </CardContent>
                <CardContent className={"text-align-center"} style={{ marginTop: "-10px", color: "#666" }}>
                  <p>
                    Ved å opprett en bruker godtar du Påmeldinger.no sine{" "}
                    <Link external target="_blank" href="https://pameldinger.no/vilkar-for-brukere">
                      Vilkår for brukere
                    </Link>{" "}
                    og vår{" "}
                    <Link external target="_blank" href="https://pameldinger.no/personvern">
                      Personvernerklæring
                    </Link>
                    .
                  </p>
                </CardContent>
              </Card>
              <Block className="text-align-center" style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                <Button outline href="/">
                  Allerede bruker? Logg inn
                </Button>
              </Block>
            </Col>
          </Row>
        </PageBlock>
        <IwdBottomBar />
      </Page>
    );
  }
}
