import React from 'react';
import {
    Page,
    Card,
    CardContent,
    Icon,
    Block,
    List,
    Button,
    ListInput,
    Preloader,
} from 'framework7-react';

import {API_URL} from '../../../api';
import PageBlock from '../../shared/pageBlock/pageBlock';

export default class extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showSendBtn: true,
            loading: false,
            loading_invite: true,
            invite_error: '',
            name: '',
            email: '',
            password: '',
            password2: '',
            //error
            errMgs: null,
            formError: false
        };
    }

    componentDidMount(){
        document.title = 'Påmeldinger.no - Invitasjon';
        this.loadXhr();
    }

    loadXhr(){

        const sURL = this.$f7route.params.sURL;

        this.setState({
            loading_invite: true,
        });

        fetch(API_URL+'/user/password/reset/check', {
            method: 'POST',
            body: JSON.stringify({
                sURL: sURL,
            })
        })
        .then(response => {
        return response.json()
        })
        .then(data => {
            if(data.err_code === 0){
                this.setState({
                    loading_invite: false,
                    invite_error: '',
                    email: data.email
                });
            }else{
                this.setState({
                    loading_invite: false,
                    invite_error:  data.mgs,
                });
            }

        })
        .catch(err => {
        this.setState({
            loading_invite: false,
            invite_error: 'En ukjent feil har oppstått, prøv igjen senere.'
        });
        })

    }

        //Update password
        updatePassword(e){
            this.setState({
              password: e.target.value,
              showSendBtn: true,
              loading: false,
              showSuccessMgs: false,
            });
        }
        //Update password
        updateConfirmPassword(e){
            this.setState({
              error_password_not_equal:false,
              password2: e.target.value,
              showSendBtn: true,
              loading: false,
              showSuccessMgs: false,
            });
        }



        checkForm(){
            const app = this.$f7;
            
            if(!app.input.validateInputs('.req_register')){
              return false;
            }

            if(this.state.password !== this.state.password2){
              this.setState({
                  error_password_not_equal:true
              });
              return false;
          }

          this.postXhr();

        }
    
        postXhr() {
            const self = this;

            self.setState({
                showSendBtn: false,
                loading: true,
            });
    
            fetch(API_URL+'/user/password/reset/update', {
              method: 'POST',
              body: JSON.stringify({
                password: this.state.password,
                sURL: this.$f7route.params.sURL
              }),
              headers : new Headers(),
            })
            .then(res => res.json())
            .then(
              (result) => {
                if(result.err_code === 0){
                  
                  this.sucess();
    
                }else{
                    self.setState({
                    errMgs: result.err_mgs,
                    showSendBtn: true,
                    loading: false,
                    showSuccessMgs: false,
                  });
                }
              },
              (error) => {
                this.setState({
                  errMgs: 'En ukjent feil har oppstått, prøv igjen senere.',
                  showSendBtn: true,
                  loading: false,
                  showSuccessMgs: false,
                });
              }
            )
          }

        sucess(){
            window.location.replace('/');
        }

  render() {
    return (
    <Page>
             {this.state.loading_invite
        ? (
            <div className="text-align-center" style={{margin:'26px'}}>
              <Preloader></Preloader>
            </div>
        ) : (
            <PageBlock width="380px">
            {this.state.invite_error
            ? (
                <>
                <Card>
                    <CardContent style={{color:'#fff', backgroundColor:'#e74c3c'}}>
                        <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                        <span style={{fontSize:'18px'}}>{this.state.invite_error}</span>
                    </CardContent>
                </Card>
                <br/>
                </>
            ) : (
            <>
             <Block>
                <h3>Resett passord</h3>
                <p className="text-align-center" style={{fontSize:'12px',color:'#666',paddingLeft:'20px',paddingRight:'20px'}}>
                Skriv inn det nye passordet til {this.state.email}.
                </p>
            </Block>
            {this.state.errMgs &&
                <Card style={{color:'#fff', backgroundColor:'#e74c3c', marginTop:'36px'}}>
                  <CardContent>
                    <Icon material="error" style={{marginRight:'12px',marginTop:'-5px'}}/>
                    <span style={{fontSize:'18px'}}>{this.state.errMgs}</span>
                  </CardContent>
                </Card>
                }
                
            <Card style={{marginBottom:'32px'}}>
                <CardContent style={{paddingTop:'28px'}}>
                <List className="req_register">
                    <ListInput
                        outline
                        label="Nytt passord"
                        type="password"
                        placeholder="Opprett nytt passord"
                        value={this.state.password}
                        onChange={(e) => this.updatePassword(e)}
                        required
                        pattern="^.{6,}$"
                        errorMessage="Passordet må være minimum 6 bokstaver"
                    /><ListInput
                        outline
                        label="Gjenta nytt passord"
                        type="password"
                        placeholder="Gjenta nytt passord"
                        value={this.state.password2}
                        onChange={(e) => this.updateConfirmPassword(e)}
                        errorMessage="Passordet er ikke likt"
                        errorMessageForce={this.state.error_password_not_equal}
                    />
                </List>
                {this.state.loading &&
                <Block className="text-align-center" style={{marginTop:'40px', marginBottom:'20px'}}>
                    <Preloader></Preloader>
                </Block>
                }
                {this.state.showSendBtn &&
                <Block style={{marginTop:'34px', marginBottom:'10px'}}>
                    <Button onClick={() => this.checkForm()} large raised fill>Oppdater passord</Button>
                </Block>
                }
                </CardContent>
            </Card>
            </>
            )}
      </PageBlock>
      )}
    </Page>
    );
  }
};
