import React from 'react';

export default class extends React.Component {

    componentDidMount(){
        document.title = 'Påmeldinger.no - Vent litt';
        let url = new URL(window.location.href.replace('#',''));

        const urlParams = new URLSearchParams(url.search);

        const params_error = urlParams.get('error');
        const params_code = urlParams.get('code');
        const params_linkedin_redirect_url = urlParams.get('linkedin_redirect_url');

        if (params_error) {
            const params_state = urlParams.get('state');
            const params_error_description = urlParams.get('error_description');
            const errorMessage = params_error_description || 'Login failed. Please try again.';
            window.opener && window.opener.postMessage({ error: params_error, state: params_state, errorMessage, from: 'Linked In' }, window.location.origin);
            // Close tab if user cancelled login
            if (params_error === 'user_cancelled_login') {
                window.close();
            }
        }
        if (params_code) {
            const params_state = urlParams.get('state');
            window.opener && window.opener.postMessage({ code: params_code, state: params_state, from: 'Linked In'}, window.location.origin);
        }
        if(params_linkedin_redirect_url) {
            window.location.href = params_linkedin_redirect_url
        }
    }
    render() {
        return null;
    }
};
